export default {
  issue_type: {
    rows: [{
      id: 1,
      name: 'ปัญหาภายนอก',
      remark: ''
    }, {
      id: 2,
      name: 'ปัญหาภายใน',
      remark: ''
    }, {
      id: 3,
      name: 'ปัญหาจากลูกค้า',
      remark: ''
    }, {
      id: 4,
      name: 'ปัญหาจากซัพพลายเออร์',
      remark: ''
    }],
    paging: {
      rowTotal: 1
    }
  },
  search_filters: {
    issue_filters: [{
      filter_count: 0,
      id: 1,
      name: 'ปัญหาภายนอก',
      remark: ''
    }, {
      filter_count: 0,
      id: 2,
      name: 'ปัญหาภายใน',
      remark: ''
    }, {
      filter_count: 0,
      id: 3,
      name: 'ปัญหาจากลูกค้า',
      remark: ''
    }, {
      filter_count: 0,
      id: 4,
      name: 'ปัญหาจากซัพพลายเออร์',
      remark: ''
    }]
  }
}
